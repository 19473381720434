<template>
	<div class="animate__animated animate__fadeIn">
		<top-bar>
			<div class="top-box">
				<img :src="logoImg" class="logo">
				<div class="menus-box" :style="fontColor">
					<div class="menus" v-for="(m,mi) in menus" :style="active == m.path ? {fontWeight:600} : ''" :key="mi" @mouseover="changeOver(m)" :class="m.hover ? 'hover-bg' : '' " @mouseleave="mouseLeave(m)" @click.stop="changePath(m)">
						{{m.name}}
						<div class="dt-line" v-if="active == m.path" :style="dtBg"></div>
						<div class="se-me hover-bg" v-if="m.hover" >
							<div class="child" :style="fontColor" v-for="(c,ci) in m.child" :key="ci" @click.stop="changePath(c)">
								{{c.name}}
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</top-bar>
		 <div class="wrap" >
				<swiper ref="mySwiper" :options="swiperOptions" class="s_t">
						<swiper-slide >
							<home v-if="swiperIndex == 0"></home>
						</swiper-slide>
						<swiper-slide >
							<solution v-if="swiperIndex == 1"></solution>
						</swiper-slide>
						<swiper-slide >
							<technlog v-if="swiperIndex == 2"></technlog>
						</swiper-slide>
						<!-- <swiper-slide>
							<manufacture v-if="swiperIndex == 3" ></manufacture>
						</swiper-slide> -->
						<swiper-slide>
							<value v-if="swiperIndex == 3"></value>
						</swiper-slide>
						<swiper-slide >
							<case v-if="swiperIndex == 4"></case>
						</swiper-slide>
						<swiper-slide>
							<cooperation v-if="swiperIndex == 5"></cooperation>
						</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
		    </div>
	</div>
</template>

<script>
	import Value from '@/components/index/value.vue'
	import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
	import Home from '@/components/index/home.vue'
	import solution from '@/components/index/solution.vue'
	import Case from '@/components/index/case.vue'
	import topBar from '@/components/header-bar.vue'
	import technlog from '@/components/index/technolog.vue'
	import manufacture from '@/components/index/manufacture.vue'
	import cooperation from '@/components/index/cooperation.vue'
	import utils from '@/utils/common.js'
	let that = this
	export default{
		components:{ 
			topBar,
			Swiper,
			SwiperSlide,
			Home,
			solution,
			technlog,
			manufacture,
			Value,
			Case,
			cooperation
		}, 
		mounted() {
			    window.onresize = () => {
			        this.H = window.innerHeight
			    }
		},
		data(){
			return{
				H:window.innerHeight,
				name:'Index',
				menus:utils.menus,
				active:'Index',
				swiperIndex:0,
				swiperOptions: { // 设置你想要得排序效果，可以在swiper官网api文档查看更多
					direction:"vertical",
					grabCursor: true, 
					setWrapperSize: true, 
					autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
					slidesPerView: 1,
					mousewheel: true, 
					setWrapperSize :true,
					// observer:true,
					// observeParents:true,
					resizeObserver:true,
					mousewheelControl: true, 
					// 高度设置，占满设备高度
					height: window.innerHeight,
					resistanceRatio: 0, 
					observeParents: true, 
					on:{
						slideChangeTransitionEnd : (e)=>{
							this.swiperIndex = this.$refs.mySwiper.swiperInstance.activeIndex
							// console.log(this.$refs.mySwiper.swiperInstance.activeIndex)
							
						},
						resize: ()=>{
							 // console.log(this.$refs.mySwiper)
							 // this.$refs.mySwiper.swiperInstance = window.inn
							 
						}
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					},
					

			}
		},
		computed:{
			logoImg(){
				// console.log(this.swiperIndex,'lll')
				if(this.swiperIndex == 0){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 1){
					return require('../static/qlogo.png')
				}
				else if(this.swiperIndex == 2){
					return require('../static/wlogo.png')
				}
				// else if(this.swiperIndex == 3){
				// 	return require('../static/qlogo.png')
				// }
				else if(this.swiperIndex == 3){
					return require('../static/qlogo.png')
				}
				else if(this.swiperIndex == 4){ 
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 5){
					return require('../static/qlogo.png')
				}
				
			},
			fontColor(){
				if(this.swiperIndex == 0){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						color:'#fff'
					}
				}
				// else if(this.swiperIndex == 3){
				// 	return {
				// 		color:'#333'
				// 	}
				// }
				else if(this.swiperIndex == 3){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						color:'#333'
					}
				}
			},
			dtBg(){
				if(this.swiperIndex == 0){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						background:'#fff'
					}
				}
				// else if(this.swiperIndex == 3){
				// 	return {
				// 		background:'#333'
				// 	}
				// }
				else if(this.swiperIndex == 3){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						background:'#333'
					}
				}
			}
		},
		methods:{
			changeOver(m){
				// console.log(m)
				if(m.path == 'solution'){
					m.hover = true
					this.active = m.path
				}
			},
			mouseLeave(m){
				this.menus.forEach(item=>item.hover = false)
				this.active = this.name
			},
			changePath(item){
				if(item.path != this.name && item.path != 'solution'){
					this.$router.push({
						name:item.path
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>


.wrap {    
    width: 100%;
    // height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
    overflow: hidden;
    // background: red;
	margin: 0;
	padding: 0;
	
}
.s_t{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
	.active-h{
		font-weight: 600;
	}
	.top-box{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		// background: #007AFF;
		justify-content: space-between;
		.logo{
			width: 42px;
			height: 47px;
			margin-left: 11.25rem;
		}
		.menus-box{
			height: 100%;
			display: flex;
			align-items: center;
			margin-right: 197px;
			position: relative;
		}
		.menus{
			height: 100%;
			width: 114px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			// margin-right: 60px;
			font-size: 14px;
			font-weight: 400;
			
			cursor: pointer;
			box-sizing: border-box;
			position: relative;
			.dt-line{
				width: 20px;
				height: 4px;
				// background: #FFFFFF;
				position: absolute;
				bottom: 16px;
			}
			.se-me{
				position: absolute;
				width: 114px;
				height: 108px;
				bottom: -108px;
			}
			.child{
				width: 100%;
				height: 54px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 400;
				// color: #FFFFFF;
			}
		}
	}
	
	.seven_box{
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		.seven-title{
			width: 100%;
			height: 372px;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 110px;
			.title-box{
				width: 384px;
				height: 70px;
				font-size: 58px;
				font-weight: bold;
				color: #F7F7F7;
				position: relative;
				.cont{
					font-size: 32px;
					font-weight: 600;
					color: #1A1A1A;
					position: absolute;
					bottom: 0;
					left: 20%;
				}
			}
			.schol-box{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 53px;
				.bg{
					margin-right: 40px;
				}
			}
		}
		
		// 
		.foot-box{
			width: 100%;
			// min-height: 365px;
			background: #000C33;
			padding-top: 90px;
			position: relative;
			flex: 1;
			.line_box{
				width: 100%;
				display: flex;
				justify-content: center;
				.logo{
					width: 98px;
					height: 110px;
					margin-right: 110px;
				}
				.foot-title-box{
					margin-right: 70px;
					.header-title{
						font-size: 16px;
						font-weight: 500;
						color: #FFFFFF;
						margin-bottom: 24px;
					}
					.content-box{
						display: flex;
						align-items: center;
						margin-bottom: 20px;
						.icon{
							width: 20px;
							height: 20px;
							margin-right: 8px;
						}
						.con{
							max-width: 240px;
							font-size: 14px;
							font-weight: 400;
							color: #F2F2F2;
							
						}
					}
					
				}
				.qr{
					width: 119px;
					height: 119px;
					margin-top: 40px;
				}
			}
			.foot-bottom{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0px;
				height: 49px;
				background: #0C1949;
				z-index: 999;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
		
		
	}

	
</style>

<template>
	<div>
		<div class="header-box" :class="shadow ? 'shaw' : ''">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			shadow:{
				type:Boolean,
				default:false
			}
		}
	}
</script>

<style lang="less" scoped>
	.header-box{
		width: 100%;
		height: 80px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999;
	}
	.shaw{
		box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.04);
	}
</style> 

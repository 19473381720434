<template>
	<div>
		<div class="two-box" >
			<div class="title-top animate__animated animate__bounceInDown animate__fast">
				<div class="t-box">
					<div class="ti">Solution</div>
					<div class="t2">解决方案</div>
				</div>
			</div>
			<div class="text-box animate__animated animate__bounceInLeft animate__fast">
				<div class="dot-title-box animate__animated animate__rotateInDownLeft ">
					<div class="dot"></div>
					<div class="title">高效的产业链协同网络</div>
				</div>
				<div class="dot-title-box animate__animated animate__rotateInDownLeft ">
					<div class="dot"></div>
					<div class="title">灵活的技术改造方案</div>
				</div>
				<div class="dot-title-box animate__animated animate__rotateInDownLeft ">
					<div class="dot"></div>
					<div class="title">专业的减碳数据测算</div>
				</div>
			</div>
			<div style="width: 100%;display: flex;align-items: center; justify-content: center;position: absolute;bottom: -10px;left: 180px;z-index: 10;">
				<div class="se-box animate__animated animate__bounceInRight "></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		
	}
</script>

<style lang="scss" scoped>
	.two-box{
		width: 100%;
		height: 100vh;
		background: url(../../static/home/jiejue.png);
		background-size: 100% 100%;
		padding-top: 100px;
		position: relative;
		z-index: -1;
		box-sizing: border-box;
		.title-top{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.t-box{
				width: 244px;
				height: 58px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				.ti{
					height: 58px;
					font-size: 58px;
					font-weight: bold;
					color: #F7F7F7;
				}
				.t2{
					position: absolute;
					font-size: 32px;
					font-weight: 600;
					color: #1A1A1A;
					bottom: 0;
				}
			}
		}
		
		.text-box{
			width: 318px;
			height: 174px;
			background: rgba(255, 255, 255, 0.2);
			box-shadow: 3px 3px 20px 3px rgba(0, 0, 0, 0.04);
			border-radius: 4px;
			margin-top: 10px;
			margin-left: 180px;
			padding: 0 28px;
			position: relative;
			z-index: 999;
			padding-top: 28px;
			box-sizing: border-box;
			z-index: 100;
			.dot-title-box{
				width: 100%;
				display: flex;
				align-items: center;
				margin-bottom: 24px;
				.dot{
					width: 16px;
					height: 16px;
					background: linear-gradient(320deg, rgba(103, 165, 255, 0.05) 0%, #77AEFF 100%);
					margin-right: 12px;
					border-radius: 50%;
				}
				.title{
					font-size: 18px;
					font-weight: 400;
					color: #4D4D4D;
				}
			}
		}
		.se-box{
			width: 921px;
			height: 627px;
			background: url(../../static/home/sebg.png);
			background-size: 100% 100%;
			// position: absolute;
			// bottom: 80px;
			// left: 401px;
		}
	}
</style>

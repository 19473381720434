<template>
	<div>
		<div class="six_box" >
			<div class="bg"></div>
			<div class="pro_ti-box">
				<div class="pro_viw">
					<div class="animate__animated animate__bounceInLeft">Project Case </div>
					<div class="pro_title animate__animated animate__bounceInRight">项目案例</div>
				</div>
			</div>
			<div style="width: 100%;display: flex;align-items: center;justify-content: center;" class="animate__animated animate__bounceInUp">
				<div class="swp-box">
					<swiper ref="proSwiper" :option="proOption" style="width: 1004px;height: 493px;">
						<swiper-slide class="swiper-slide">
							<div class="pr_cla">
								<img src="../../static/home/proImg6.png" class="img"/>
								<div class="dt_li"></div>
								<div class="in-box">
									<div class="title">
										固碳预拌混凝土国内首次应用,助力国电打造零碳变电站
									</div>
									<div class="content">
										国家电网浙江电力公司在湖州安吉开发区新建的零碳变电站，首次在国内大规模商业化应用有碳封存属性的固碳预拌混凝土,更是实现了CCUS（碳捕集、利用与封存）技术在国内预拌混凝土领域的突破。
									</div>
								</div>
							</div>
							<button class="btn-box" @click="goInfo('CaseInfo3')">
								<span>查看详情</span>
								<img src="../../static/home/youjiantou.png" class="next"/>
							</button>
						</swiper-slide>
					    <swiper-slide class="swiper-slide">
							<div class="pr_cla">
								<img src="../../static/home/proImg4.png" class="img"/>
								<div class="dt_li"></div>
								<div class="in-box">
									<div class="title">
										CCUS技术助推全国首个施工期内实现碳中和项目落地
									</div>
									<div class="content">
										为实现建筑项目在施工期内达到碳中和，利用CCUS技术生产的固碳混凝土建材近期在我国香港地区进行使用，这也是首个在中国应用二氧化碳矿化混凝土建材的建筑项目。
									</div>
								</div>
							</div>
							<button class="btn-box" @click="goInfo('CaseInfo2')">
								<span>查看详情</span>
								<img src="../../static/home/youjiantou.png" class="next"/>
							</button>
						</swiper-slide>
						<swiper-slide class="swiper-slide">
							<div class="pr_cla">
								<img src="../../static/home/proImg.png" class="img"/>
								<div class="dt_li"></div>
								<div class="in-box">
									<div class="title">
										全球首个工业规模CO₂矿化养护混凝土示范工程
									</div>
									<div class="content">
										示范工程在河南的混凝土企业内通过72 小时运行。项目实现了每年1万吨的CO₂温室气体封存， 并生产1亿块MU15 标准的轻质实心混凝土砖，是全球第一个工业规模CO₂ 矿化养护混凝土示范工程，全生命周期减排效果全球领先。 
									</div>
								</div>
							</div>
							<button class="btn-box" @click="goInfo('CaseInfo')">
								<span>查看详情</span>
								<img src="../../static/home/youjiantou.png" class="next"/>
							</button>
						</swiper-slide>
					</swiper>
					<div class="left-t" @click="prev()"></div>
					<div class="right-t" @click="next()"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
	export default{
		components:{
			Swiper, 
			SwiperSlide
		},
		data(){
			return{
				proOption:{
					direction:"horizontal",
					effect:'fade',
					autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
					height: 493,
					width:1044,
					watchOverflow: true, //当没有足够的slide切换时，例如只有1个slide（非loop），swiper会失效且隐藏导航等。默认不开启这个功能。
					loop: true,//开启循环模式
					slidesPerView: 1,
					spaceBetween: 30,
					preventClicksPropagation: true,//阻止click冒泡。拖动Swiper时阻止click事件。
					simulateTouch: false,//鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。
					on:{
						slideNext:()=>{
							this.$refs.proSwiper.slideNext()
						}
					},
				}
			}
		},
		methods:{
			next(){
				this.$refs.proSwiper.swiperInstance.slideNext()
			},
			prev(){
				this.$refs.proSwiper.swiperInstance.slidePrev()
			},
			goInfo(i){
				//this.$router.push({
				//	name:'CaseInfo'
				//})
				let routeData = this.$router.resolve({ name: i, query: {  id: 1 } });
				window.open(routeData.href, '_blank');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.six_box{
		width: 100%;
		height: 100vh;
		background: url(../../static/home/sixbg.png);
		background-size: 100% 100%;
		position: relative;
		padding-top: 128px;
		.bg{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			background: rgba(0,0,0,.5);
		}
		.pro_ti-box{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			
		}
		.pro_viw{
			width: 368px;
			height: 58px;
			font-size: 58px;
			position: relative;
			font-weight: bold;
			color: rgba(247, 247, 247, 0.1);
			.pro_title{
				font-size: 32px;
				position: absolute;
				bottom: 0;
				left: 38%;
				font-weight: 600;
				color: #FFFFFF;
			}
		}
		.swp-box{
			width: 1210px;
			min-height: 493px;
			display: flex;
			// background: #FF0000;
			align-items: center;
			// padding-left: 90px;
			box-sizing: border-box;
			margin-top: 62px;
			justify-content: center;
			position: relative;
			.left-t{
				width: 44px;
				height: 44px;
				background: url(../../static/home/zuojiantou.png);
				background-size: 100% 100%;
				position: absolute;
				left: 0;
				cursor: pointer;
				z-index: 999;
			}
			.right-t{
				width: 44px;
				height: 44px;
				background: url(../../static/home/youjiantou.png);
				background-size: 100% 100%;
				position: absolute;
				right: 0;
				cursor: pointer;
				z-index: 999;
			}
			.pr_cla{
				width: 100%;
				height: 493px;
				position: relative;
				.img{
					width: 600px;
					height: 439px;
					position: absolute;
					left: 0;
					top: 0;
				}
				.dt_li{
					width: 335px;
					height: 27px;
					background: #2EDB08;
					position: absolute;
					right: 69px;
					top: 36px;
				}
				.in-box{
					width: 850px;
					height: 439px;
					background: #FFFFFF;
					position: absolute;
					z-index: -1;
					top: 63px;
					left: 100px;
					display: flex;
					align-items: flex-end;
					flex-direction: column;
					padding: 0 20px;
					box-sizing: border-box;
					
					.title{
						width: 303px;
						height: 66px;
						font-size: 24px;
						font-weight: 500;
						color: #1A1A1A;
						margin-top: 40px;
					}
					.content{
						width: 303px;
						height: 196px;
						font-size: 16px;
						font-weight: 400;
						color: #666666;
						margin-top: 24px;
					}
	
				}
	
			}
			.btn-box{
				width: 158px;
				height: 65px;
				background: #075AD3;
				border: 0;
				margin: 0;
				font-size: 20px;
				font-weight: 500;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				bottom: 24px;
				cursor: pointer;
				right: 10px;
				.next{
					width: 20px;
					height: 20px;
					margin-left: 6px;
				}
			}
	
		}
	}
</style>

<template>
	<div>
		<div class="three-box" >
			<div style="width: 100%;display: flex;align-items: center; justify-content: center;flex-direction: column;">
				<div class="t-box animate__animated animate__bounceInDown ">
					<span class="title animate__animated animate__bounceInLeft">CO₂矿化养护混凝土技术</span>
					<div class="content animate__animated animate__bounceInRight">
						CO₂矿化养护混凝土技术，可以在不降低混凝土建材质量的同时，降低原材料成本，实现固废的循环利用，同时将捕集的废弃CO₂高效利用到混凝土的制造流程环节中，实现将CO₂封存在混凝土中，打造低碳的绿色建材。
					</div>
					<button class="more-btn animate__animated animate__bounceInRight" @click="goPage">了解更多</button>
				</div>
				<div class="b-box">
					<div class="l-p animate__animated animate__bounceInLeft">
						<img src="../../static/home/sco2.png" class="l-1"/>
						<span class="t-1">产品自身减少碳排放</span>
						<div class="c-box">
							<span class="c1">全生命周期减少碳排放约</span>
							<span class="c2">80%</span>
						</div>
					</div>
					<div class="l-p animate__animated animate__bounceInUp" style="margin-left: 256px;margin-right: 252px;">
						<img src="../../static/home/sco3.png" class="l-1"/>
						<span class="t-1">有效封存利用废弃CO₂ </span>
						<div class="c-box">
							<span class="c1">工业中捕集废弃的CO₂转化率达</span>
							<span class="c2">98%</span>
						</div>
					</div>
					<div class="l-p animate__animated animate__bounceInRight">
						<img src="../../static/home/sco4.png" class="l-1"/>
						<span class="t-1">工业废料循环利用</span>
						<div class="c-box">
							<span class="c1">原材料中水泥用量仅占比</span>
							<span class="c2">10%</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		methods:{
			goPage(){
				//this.$router.push({
				//	name:'Technology'
				//})
				let routeData = this.$router.resolve({ name: 'Technology', query: {  id: 1 } });
				window.open(routeData.href, '_blank');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.three-box{
		width: 100%;
		height: 100vh;
		background: url(../../static/home/bianbg.png);
		background-size: 100% 100%;
		padding-top: 153px;
		.t-box{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			.title{
				font-size: 24px;
				font-weight: 500;
				color: #FFFFFF;
			}
			.content{
				width: 638px;
				height: 90px;
				text-align: center;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				margin-top: 20px;
			}
			.more-btn{
				width: 162px;
				height: 52px;
				background: #075AD3;
				line-height: 53px;
				color: #FFFFFF;
				font-size: 18px;
				border: 0;
				margin: 0;
				margin-top: 24px;
				cursor: pointer;
			}
		}
		
		.b-box{
			width: 100%;
			margin-top: 110px;
			display: flex;
			align-items: center;
			justify-content: center;
			.l-p{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				// margin-right: 107px;
				.l-1{
					width: 144px;
					height: 144px;
				}
				.t-1{
					font-size: 24px;
					font-weight: 500;
					color: #F2F2F2;
				}
				.c-box{
					display: flex;
					align-items: center;
					.c1{
						font-size: 16px;
						font-weight: 400;
						color: #F2F2F2;
					}
					.c2{
						font-size: 40px;
						font-weight: 500;
						color: #FFFFFF;
						margin-left: 5px;
					}
				}
			}
		}
		
	}
	
</style>


export default{ 
	menus:[{
	name:'首页',
	path:'Index'
	
},
{
	name:'解决方案',
	path:'solution',
	hover:false,
	child:[{
		name:'核心技术',
		path:'Technology'
	},{
		name:'项目案例',
		path:'Project'
	}]
	
},
{
	name:'最新动态',
	path:'Dynamic'
	
},
// {
// 	name:'专利奖项',
// 	path:'Prize'
	
// },
{
	name:'关于我们',
	path:'About'
	 
}]
}
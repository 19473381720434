<!--
 * @Descripition : this is descripition
 * @Author       : Stay
 * @Date         : 2021-08-23 10:51:32
 * @LastEditors  : Do not edit
 * @LastEditTime : 2021-10-17 15:04:30
 * @FilePath     : /zachsite/pc/src/App.vue
-->
<!--
 * @Descripition : this is descripition
 * @Author       : Stay
 * @Date         : 2021-08-23 10:51:32
 * @LastEditors  : Do not edit
 * @LastEditTime : 2021-10-17 11:01:08
 * @FilePath     : /zachsite/pc/src/App.vue
-->
<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style>
	@font-face {
		font-family: myFonts;
		src: url("./static/fonts/PingFang 简体.ttf");
	}
	@import url("./css/animate.min.css");
	.hover-bg{
		background: rgba(26, 26, 26, 0.4);
	}
	body{
		padding: 0;
		margin: 0;
		font-family: myFonts !important;
	}
	.text-center {
		text-align: center;
	}
	.lh-30 {
		line-height: 30px;
	}
	.white {
		color: #ffffff;
	}
</style>

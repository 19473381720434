<template>
	<div>
		<div class="seven_box" >
			<div class="seven-title">
				<div class="title-box">
					<div class="animate__animated animate__bounceInLeft">Cooperative </div>
					<div class="cont animate__animated animate__bounceInRight">合作伙伴&客户</div>
				</div>
				<div class="schol-box">
					<img :src="img" class="bg animate__animated animate__bounceInDown" v-for="(img,mi) in imgList" :key="mi" />
				</div>
				<div class="schol-box">
					<img :src="img" class="bg animate__animated animate__bounceInDown" v-for="(img,mi) in imgList1" :key="mi" />
				</div>
			</div>
			
			<div class="foot-box animate__animated animate__bounceInUp">
				<div class="line_box">
					<img src="../../static/wlogo.png"  class="logo"/>
					<div class="foot-title-box" v-for="(item,index) in footMenus" :key="index"  >
						<div class="header-title"  @click="goPage(item)">{{item.title}}</div>
						<div class="content-box" v-for="(c,ci) in item.child" :key="ci" @click.stop="goChildPage(c)">
							<img :src="c.icon" class="icon" v-if="c.icon"/>
							<span class="con">{{c.title}}</span>
						</div>
					</div>
					<img src="../../static/home/aquery.png" class="qr"/>
				</div>
				<div style="height: 49px;"></div>
				<div class="foot-bottom">
					<div style="display: flex;align-items: center; justify-content: center;width: 100%;">
						
						©2021 Cleanco2 Co.,Ltd. All rights reserved.<a href="http://beian.miit.gov.cn/" target="_blank" style="font-size: 9px;color: #fff;">京ICP备2021023826号</a>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				imgList:[require('../../static/home/logo/sh1.png'),require('../../static/home/logo/sh2.png'),require('../../static/home/logo/sh4.png'),require('../../static/home/logo/sh5.png')],
				imgList1:[require('../../static/home/logo/sh6.png'),require('../../static/home/logo/sh7.png'),require('../../static/home/logo/sh3.png')],
				footMenus:[{
					title:'关于我们',
					hasShow:true,
					child:[{
						title:'公司介绍',
						icon:'',
						path:'About'
					}]
				},{
					title:'解决方案',
					hasShow:true,
					child:[{
						title:'核心技术',
						icon:'',
						path:'Technology'
					},{
						title:'项目案例',
						icon:'',
						path:'Project'
					}]
				},{
					title:'最新动态',
					hasShow:false,
					path:'Dynamic'
				},{
					title:'联系我们',
					hasShow:true,
					child:[{
						title:'Email：hi@cleanco2.cn ',
						icon:require('../../static/home/email.png')
					},{
						title:'地址： 北京市朝阳区东三环北路38号好世界商业广场',
						icon:require('../../static/home/address.png')
					}]
				}],
				path:'Index'
			}
		},
		methods:{
			goChildPage(e){
				// console.log(this.path,e.path)
				if(e.path && this.path != e.path){
					this.$router.push({
						name:e.path
					})
				}
			},
			goPage(item){
				if(!item.hasShow && item.path != this.path){
					this.$router.push({
						name:item.path
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.seven_box{
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		.seven-title{
			width: 100%;
			height: 450px;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 110px;
			.title-box{
				width: 384px;
				height: 70px;
				font-size: 58px;
				font-weight: bold;
				color: #F7F7F7;
				position: relative;
				.cont{
					font-size: 32px;
					font-weight: 600;
					color: #1A1A1A;
					position: absolute;
					bottom: 0;
					left: 20%;
				}
			}
			.schol-box{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 35px;
				.bg{
					margin-right: 15px;
					margin-left: 15px;
				}
			}
		}
		
		// 
		.foot-box{
			width: 100%;
			// min-height: 365px;
			background: #000C33;
			padding-top: 90px;
			position: relative;
			flex: 1;
			.line_box{
				width: 100%;
				display: flex;
				justify-content: space-around;
				.logo{
					width: 98px;
					height: 110px;
					margin-left: 180px;
					margin-right: 110px;
				}
				.foot-title-box{
					margin-right: 70px;
					.header-title{
						font-size: 16px;
						cursor: pointer;
						font-weight: 500;
						color: #FFFFFF;
						margin-bottom: 24px;
					}
					.content-box{
						display: flex;
						align-items: flex-start;
						margin-bottom: 20px;
						cursor: pointer;
						.icon{
							width: 20px;
							height: 20px;
							margin-right: 8px;
						}
						.con{
							max-width: 240px;
							font-size: 14px;
							font-weight: 400;
							color: #F2F2F2;
							
						}
					}
					
				}
				.qr{
					width: 119px;
					height: 119px;
					margin-top: 40px;
					margin-right: 180px;
				}
			}
			.foot-bottom{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0px;
				height: 49px;
				background: #0C1949;
				z-index: 999;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				font-weight: 500;
				color: #FFFFFF;
				a:link{text-decoration: none;}
				a{text-decoration: none;}
			}
		}
		
		
	}
</style>

<template>
	<div>
		<div >
			<swiper ref="homeSwiper" :options="homeOptions" style="height: 615px;">
				<swiper-slide style="height: 100%;">
					<div class="one-img-box">
						<div style="width: 100%;height: 100%; padding-top: 233px;padding-left: 183px;">
							<div class="text-img"></div>
							<div class="title-b">
								<div class="lh-30 white">清洁捕获是一家利用人工技术清除CO₂的创新企业。致力于在混凝土建筑等行业，</div>
								<div class="lh-30 white"> 通过对碳捕捉、利用与封存（CCUS）技术的研发与商业化，为社会提供低成本的除碳技术解决方案，帮助构建未来“净零排放”的工业经济体系</div>
	
							</div>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide style="height: 100%;">
					<div class="two-img-box">
						<div style="width: 100%;height: 100%; padding-top: 463px;padding-left: 183px;">
							<div class="text-img" style=""></div>
							<div class="title-b">
								<div class="lh-30 white">全世界每年消耗混凝土远超170亿吨，占全球碳排放总量的5-7%</div>
								<div class="lh-30 white">在迫切的需求和紧迫的环境压力之间，清洁捕获探索可持续路径，将混凝土材料都转化成为地球的碳汇资源</div>
							</div>
						</div>
					</div>
				</swiper-slide>
				<div class="swiper-scrollbar" slot="pagination"></div>
			</swiper>
			<div class="bottom-box">
				<div class="lef-box animate__animated animate__bounceInLeft animate__fast">
					<img src="../../static/home/co2.png" class="coimg">
					<div class="num">
						<div>
							<span class="t1"><countTo :startVal='startVal' :endVal='endVal' :duration='2000' :separator="separator"></countTo></span>
							<span class="d">吨</span>
						</div>
						<div class="b1">
							二氧化碳封存
						</div>
					</div>
				</div>
				<div class="rig-box animate__animated animate__bounceInRight animate__fast">
					<img src="../../static/home/tu.png" class="coimg">
					<div class="num">
						<div>
							<span class="t1"><countTo :startVal='startVal' :endVal='endVal2' :duration='2000' :separator="separator"></countTo></span>
							<span class="d">吨</span>
						</div>
						<div class="b1">
							生产低碳混凝土
						</div>
					</div>
				</div>
				<div class="dot"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
	export default{
		components:{
			Swiper,
			SwiperSlide,
		}, 
		data(){
			return{
				homeOptions:{
					direction:"horizontal",
					effect:'fade',
					autoplay:{
						delay: 3000,
					},
					  scrollbar: {
					    el: '.swiper-scrollbar',
					    draggable: true,
					    snapOnRelease: false,
					  },
					autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
					height: 100,
					// pagination: {
					// 	el: '.swiper-paginations',
					// 	clickable: true
					// },
				},
				startVal: 0,
				endVal: 10000,
				endVal2: 172000,
				separator: ""
			}
		}
	}
</script>

<style lang="less" scoped>
	.swiper-scrollbar{
		width: 150px!important;
		height: 6px;
		background: #FFFFFF;
		bottom: 50px!important;
		left: 45%!important;
		// border-radius: 0;
	}
	.one-img-box{
		width: 100%;
		height: 100%;
		background: url(../../static/home/banner1.png);
		background-size: 100% 100%;
		
		.text-img{
			width: 575px;
			height: 47px;
			background: url(../../static/home/t1.png);
			background-size: 100% 100%;
			
		}
		.title-b{
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			margin-top: 32px;
			width: 616px;
		}
	}
	.two-img-box{
		width: 100%;
		height: 100%;
		background: url(../../static/home/banner2.png);
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.text-img{
			width: 646px;
			height: 64px;
			background: url(../../static/home/t2.png);
			background-size: 100% 100%;
			margin-bottom: 32px;
		}
	}
	.bottom-box{
		width: 100%;
		height: 180px;
		
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		.lef-box{
			display: flex;
			align-items: center;
			.coimg{
				width: 100px;
				height: 100px;
				margin-right: 40px;
			}
			.num{
				.t1{
					font-size: 40px;
					font-weight: 500;
					color: #1A1A1A;
					// font-family: DINPro-Medium, DINPro;
				}
				.d{
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					margin-left: 8px;
				}
				.b1{
					font-size: 18px;
					font-weight: 400;
					color: #1A1A1A;
				}
			}
		}
		.rig-box{
			display: flex;
			align-items: center;
			margin-left: 250px;
			.coimg{
				width: 100px;
				height: 100px;
				margin-right: 40px;
			}
			.num{
				.t1{
					font-size: 40px;
					font-weight: 500;
					color: #1A1A1A;
				}
				.d{
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					margin-left: 8px;
				}
				.b1{
					font-size: 18px;
					font-weight: 400;
					color: #1A1A1A;
				}
			}
		}
		.dot{
			position: absolute;
			background: url(../../static/home/jiantou.png);
			width: 44px;
			height: 44px;
			background-size: 100% 100%;
			left: 48%;
			bottom: 10px;
			animation: dong 1s infinite;
		}
		@keyframes dong { 
		       0% {             
		           transform: translate(0px, 0px);            
		        }            
		        50% {                
		           transform: translate(0px, -10px);            
		        }            
		        100% {                
		           transform: translate(0px, 0px);
		        }
		}
	}
</style>

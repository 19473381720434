import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
Vue.config.productionTip = false
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import countTo from 'vue-count-to'
 
Vue.use(VueAwesomeSwiper)
Vue.component('countTo', countTo)

new Vue({
router,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import Router from 'vue-router'
import Index from '../views/index.vue'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes:[
    {
      path: '/',
      name: '/',
      redirect:'/Index'
    },
    {
      path: '/Index',
      name: 'Index',
      component: Index,
      meta: {
        title: '清洁捕获官网',
        content:{
          keywords:'二氧化碳,CO₂,二氧化碳捕集,二氧化碳封存,二氧化碳利用,二氧化碳排放,CCUS,混凝土,低碳混凝土,绿色混凝土,新型混凝土,减碳,低碳,温室气体,碳捕集,碳足迹,绿色建材,低碳建材,零排放,低排放,水泥,混凝土矿化,碳中和',
          description:'清洁捕获是一家利用人工技术清除二氧化碳的创新企业。致力于通过对碳捕捉、利用与封存（CCUS）技术的研发与商业化，为社会提供低成本的除碳技术解决方案，帮助构建未来“净零排放”的工业经济体系。目前企业聚焦于建材领域，利用技术将捕集的CO₂封存在混凝土材料中，不仅能制造减少80%碳足迹的低碳混凝土产品，为建筑、基建等行业提供不增加“绿色溢价”的低碳转型方案，同时还能实现CO₂的大规模、稳定封存，将混凝土这种全世界使用量第二大的材料转变为地球的碳汇。',
        }
      }
    },
	{
	  path: '/Project',
	  name: 'Project',
	  component: () => import('../views/project.vue'),
	  meta: {
	    title: '项目案例',
	    content:null
	  }
	},
	{
	  path: '/Technology',
	  name: 'Technology',
	  component: () => import('../views/technology.vue'),
	  meta: {
	    title: '核心技术',
	    content:null
	  }
	},
	{
	  path: '/Dynamic',
	  name: 'Dynamic',
	  component: () => import('../views/dynamic.vue'),
	  meta: {
	    title: '最新动态',
	    content:null
	  }
	},
	{
	  path: '/About',
	  name: 'About',
	  component: () => import('../views/about.vue'),
	  meta: {
	    title: '关于我们',
	    content:null
	  }
	},
	{
	  path: '/Prize',
	  name: 'Prize',
	  component: () => import('../views/prize.vue'),
	  meta: {
	    title: '关于我们',
	    content:null
	  }
	},
	{
	  path: '/DynamicInfo',
	  name: 'DynamicInfo',
	  component: () => import('../views/dynamicInfo.vue'),
	  meta: {
	    title: '动态详情',
	    content:null
	  }
	},
	{
	  path: '/CaseInfo',
	  name: 'CaseInfo',
	  component: () => import('../views/caseInfo.vue'),
	  meta: {
	    title: '案例详情',
	    content:null
	  }
	},
	{
		path: '/CaseInfo2',
		name: 'CaseInfo2',
		component: () => import('../views/caseInfo2.vue'),
		meta: {
		  title: '案例详情',
		  content:null
		}
	},
	{
		path: '/CaseInfo3',
		name: 'CaseInfo3',
		component: () => import('../views/caseInfo3.vue'),
		meta: {
		  title: '案例详情',
		  content:null
		}
	}
  ]
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  console.log(to.meta.content)
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});
router.afterEach(() => {
  window.scrollTo(0,0);
})
export default router